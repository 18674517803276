import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useTranslation } from 'contexts/Localization'
import React from 'react'

const NavList = styled.ul`
  list-style: none;
  padding: 24px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: space-evenly;
  text-align: center;
`
const NavItem = styled.li`
  max-width: 140px;
  width: 100%;
  padding: 10px 0;
  transition: all 0.5s linear;
  font-size: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.active {
    background-color: #000;
    border-radius: 20px;
    color: #fff;
  }
`

interface NavProps {
  current: string
}

const PageNav: React.FC<NavProps> = ({ current, ...props }) => {
  const router = useRouter()
  const { t } = useTranslation()

  const routerList = [
    {
      url: '/swap',
      value: 'swap',
      text: t('Swap'),
    },
    {
      url: '/liquidity',
      value: 'liquidity',
      text: t('Liquidity'),
    },
  ]
  const go = (url) => {
    router.push(url)
  }

  return (
    <NavList>
      {routerList.map((i) => {
        return (
          <NavItem className={current === i.value ? 'active' : ''} onClick={() => go(i.url)}>
            {i.text}
          </NavItem>
        )
      })}
    </NavList>
  )
}

export default PageNav
